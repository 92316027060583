/**
 * This service interacts with the Navigator.mediaDevices API which is used for
 * retrieving and utilizing devices supported for the user's browser
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/Navigator/mediaDevices
 */
import { Injectable } from '@angular/core';

export const MEDIADEVICES_TYPE = {
  AUDIO: 'audioinput',
  VIDEO: 'videoinput',
};

export enum MediaDeviceResultStatus {
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum MediaDeviceErrorCode {
  MEDIA_DEVICE_API_NOT_SUPPORTED = 'MEDIA_DEVICE_API_NOT_SUPPORTED',
  PERMISSION_DENIED = 'PERMISSION_DENIED',
  NOT_FOUND = 'NOT_FOUND',
  UNSUPPORTED_BROWSER = 'UNSUPPORTED_BROWSER',
  OVERCONSTRAINED = 'OVERCONSTRAINED',
  FAILED_TO_LOAD = 'FAILED_TO_LOAD',
}

export enum MediaDeviceFacingMode {
  USER = 'user',
  ENVIRONMENT = 'environment',
}

export type RecordingState = 'inactive' | 'paused' | 'recording';

export interface MediaDeviceErrorResult {
  status: MediaDeviceResultStatus.ERROR;
  error: MediaDeviceErrorCode;
}

export type MediaDeviceResult =
  | {
      status: MediaDeviceResultStatus.SUCCESS;
      devices: readonly MediaDeviceInfo[];
    }
  | MediaDeviceErrorResult;

export type MediaDeviceStreamResult =
  | {
      status: MediaDeviceResultStatus.SUCCESS;
      stream: MediaStream;
    }
  | MediaDeviceErrorResult;

@Injectable({
  providedIn: 'root',
})
export class MediaDevices {
  private recordedParts: Blob[];
  private mediaRecorder: MediaRecorder;

  isMediaDeviceApiSupported(): boolean {
    const nav = window.navigator;
    return (
      !!nav &&
      !!nav.mediaDevices &&
      !!nav.mediaDevices.enumerateDevices &&
      !!nav.mediaDevices.getUserMedia
    );
  }

  isDisplayMediaApiSupported(): boolean {
    return !!window?.navigator?.mediaDevices?.getDisplayMedia;
  }

  isMimeTypeSupported(mimeType: string): boolean {
    return window?.MediaRecorder?.isTypeSupported(mimeType);
  }

  isVideoAvailable(): Promise<boolean> {
    if (!this.isMediaDeviceApiSupported()) {
      return Promise.resolve(false);
    }

    return window.navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(() => true)
      .catch(() => false);
  }

  isAudioAvailable(): Promise<boolean> {
    if (!this.isMediaDeviceApiSupported()) {
      return Promise.resolve(false);
    }

    return window.navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() => true)
      .catch(() => false);
  }

  getAudioDevices(): Promise<MediaDeviceResult> {
    if (!this.isMediaDeviceApiSupported()) {
      return Promise.resolve(
        this.getErrorResult(
          MediaDeviceErrorCode.MEDIA_DEVICE_API_NOT_SUPPORTED,
        ),
      );
    }

    return this.getDevices().then(devices => {
      const audioDevices = devices.filter(
        (device: MediaDeviceInfo) => device.kind === MEDIADEVICES_TYPE.AUDIO,
      );

      return this.getSuccessDevicesResult(audioDevices);
    });
  }

  getVideoDevices(): Promise<MediaDeviceResult> {
    if (!this.isMediaDeviceApiSupported()) {
      return Promise.resolve(
        this.getErrorResult(
          MediaDeviceErrorCode.MEDIA_DEVICE_API_NOT_SUPPORTED,
        ),
      );
    }

    return this.getDevices().then(devices => {
      const videoDevices = devices.filter(
        (device: MediaDeviceInfo) => device.kind === MEDIADEVICES_TYPE.VIDEO,
      );

      return this.getSuccessDevicesResult(videoDevices);
    });
  }

  getUserMedia(
    constraints: MediaStreamConstraints = {},
  ): Promise<MediaDeviceStreamResult> {
    if (!this.isMediaDeviceApiSupported()) {
      return Promise.resolve(
        this.getErrorResult(
          MediaDeviceErrorCode.MEDIA_DEVICE_API_NOT_SUPPORTED,
        ),
      );
    }

    return window.navigator.mediaDevices
      .getUserMedia(constraints)
      .then(stream => this.getSuccessStreamResult(stream))
      .catch(error => this.getErrorStreamResult(error));
  }

  /**
   * This method checks if the browser window can get the display media stream
   * (screens, windows, brower tabs), opens up a selection screen for
   * available options, before returning that media stream track
   * @param constraints instruct what sort of media stream tracks are returned
   */
  getDisplayMedia(
    constraints: MediaStreamConstraints = {},
  ): Promise<MediaDeviceStreamResult> {
    if (!this.isDisplayMediaApiSupported()) {
      return Promise.resolve(
        this.getErrorResult(
          MediaDeviceErrorCode.MEDIA_DEVICE_API_NOT_SUPPORTED,
        ),
      );
    }

    return window.navigator.mediaDevices
      .getDisplayMedia(constraints)
      .then(stream => this.getSuccessStreamResult(stream))
      .catch(error => this.getErrorStreamResult(error));
  }

  // this method is needed in order to mock the `getUserMedia()` method
  // it allows usage of a fake instead of calling `navigator` directly
  getUserMediaVideoRecord(
    constraints: MediaStreamConstraints = {},
  ): Promise<MediaDeviceStreamResult> {
    return this.getUserMedia(constraints);
  }

  stopMediaStream(mediaStream: MediaStream): void {
    mediaStream.getTracks().forEach(track => track.stop());
  }

  /**
   * Abstracted mainly to allow use in UI testing
   */
  getMediaStreamSettings(
    mediaStream: MediaStream,
    trackKind: 'audio' | 'video',
  ): MediaTrackSettings {
    const track = mediaStream.getTracks().find(t => t.kind === trackKind);
    if (!track) {
      throw new Error(`No ${trackKind} track found in media stream`);
    }
    return track.getSettings();
  }

  /**
   * This method initialises the media recorder and sets up the service to handle the blob data
   * @param mediaStream Pre-configured media stream to record onto
   * @param options
   */
  initMediaRecorder({
    mediaStream,
    options,
  }: {
    mediaStream: MediaStream;
    options: MediaRecorderOptions;
  }): void {
    this.recordedParts = [];
    this.mediaRecorder = new MediaRecorder(mediaStream, options);

    this.mediaRecorder.ondataavailable = (event: BlobEvent) => {
      if (event.data && event.data.size > 0) {
        this.recordedParts.push(event.data);
      }
    };
  }

  startMediaRecorder(): void {
    this.mediaRecorder.start();
  }

  stopMediaRecorder(): void {
    this.mediaRecorder.stop();
  }

  /**
   * When MediaRecorder.stop() is called, the data is not immediately available
   * and needs to be actioned as part of a callback function.
   * The recorded blob data is converted into a file before being passed into the given callback
   * @param callbackFn Function to run at the end of media recording, when data is available
   * @param fileName The name to give the recording file
   */
  mediaRecorderFileCallback({
    callbackFn,
    fileName,
  }: {
    callbackFn(ev: Event, file: File): void;
    fileName: string;
  }): void {
    this.mediaRecorder.onstop = (event: Event) => {
      const recordedFile = new File(this.recordedParts, fileName, {
        type: 'video/webm',
      });
      callbackFn(event, recordedFile);
    };
  }

  getMediaRecorderState(): RecordingState {
    return this.mediaRecorder.state;
  }

  private getDevices(): Promise<MediaDeviceInfo[]> {
    return window.navigator.mediaDevices.enumerateDevices();
  }

  private getErrorStreamResult(error: Error): Promise<MediaDeviceErrorResult> {
    let errorCode;
    switch (error.name) {
      case 'NotAllowedError':
      case 'PermissionDeniedError':
        errorCode = MediaDeviceErrorCode.PERMISSION_DENIED;
        break;
      case 'NotFoundError':
        errorCode = MediaDeviceErrorCode.NOT_FOUND;
        break;
      case 'UnsupportedBrowserError':
        errorCode = MediaDeviceErrorCode.UNSUPPORTED_BROWSER;
        break;
      case 'OverconstrainedError':
        errorCode = MediaDeviceErrorCode.OVERCONSTRAINED;
        break;
      default:
        errorCode = MediaDeviceErrorCode.FAILED_TO_LOAD;
    }

    return Promise.resolve(this.getErrorResult(errorCode));
  }

  private getErrorResult(error: MediaDeviceErrorCode): MediaDeviceErrorResult {
    return {
      status: MediaDeviceResultStatus.ERROR,
      error,
    };
  }

  private getSuccessDevicesResult(
    devices: readonly MediaDeviceInfo[],
  ): MediaDeviceResult {
    return {
      status: MediaDeviceResultStatus.SUCCESS,
      devices,
    };
  }

  private getSuccessStreamResult(stream: MediaStream): MediaDeviceStreamResult {
    return {
      status: MediaDeviceResultStatus.SUCCESS,
      stream,
    };
  }
}
